"use client";
import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import React, { useEffect } from "react";
import Eden from "../Eden/Eden";
import { useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

type Props = {
  data: any;
};

const EdenTitle = (props: Props) => {
  const { data } = props;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [controls, isInView]);
  const questionVariants = {
    hidden: {
      scale: 0,
      opacity: 0,
      transformOrigin: "top left",
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: 0.5,
      },
    },
  };
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  return (
    <section ref={ref} className={cn(``, sectionBackground, sectionSpacing)}>
      <div className="container  heading-eden text-black ">
        <motion.div ref={ref} className="flex gap-2 sm:gap-4 justify-center ">
          <div className="w-full max-w-[66px] sm:max-w-[90px] md:max-w-[125px] max-h-fit aspect-square">
            <div className="w-full aspect-square">
              <Eden />
            </div>
          </div>
          <motion.h3
            animate={controls}
            initial="hidden"
            variants={questionVariants}
            className="text-[38px] sm:text-[48px] md:text-h1 py-4 px-6 rounded-bl-none rounded-3xl bg-white inline-block font-bold leading-tight"
          >
            {data.heading}
          </motion.h3>
        </motion.div>
        {data.subHeading && (
          <p className="text-xl subheading-eden sm:text-h5 sm:text-center font-semibold mt-8 mb-4 text-[#051F20]">
            {data.subHeading}
          </p>
        )}
      </div>
    </section>
  );
};

export default EdenTitle;
