import {
  sectionBackgroundHandler,
  sectionSpacingHandler,
} from "@/lib/globalFunctions";
import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import { FC, useEffect } from "react";
import gsap from "gsap";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

interface Props {
  data: {
    background: string;
    spacing: string;
    heading: string;
    companyListTop: any;
    companyListBottom: any;
  };
}
function Scroller({ children }: any) {
  return <div className="scroller">{children}</div>;
}
const CompanyShowcase: FC<Props> = (props) => {
  //* https://cruip.com/create-an-infinite-horizontal-scroll-animation-with-tailwind-css/
  const { data } = props;
  // Your component logic here
  let sectionBackground = sectionBackgroundHandler(data.background);
  let sectionSpacing = sectionSpacingHandler(data.spacing);
  const ref = useRef(null);
  useGSAP(() => {
    gsap.to(".company-list-top", {
      scrollTrigger: {
        trigger: ref.current,
        start: "top 50%",
        end: "+=5000",
        scrub: 1,
      },
      x: -window.innerWidth + 500,
    });
    gsap.from(".company-list-bottom", {
      scrollTrigger: {
        trigger: ref.current,
        start: "top 50%",
        end: "+=5000",
        scrub: 1,
      },
      x: -window.innerWidth + 500,
    });
  }, [{ scope: ref }]);
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    // If a user hasn't opted in for reduced motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation(scrollers);
    }
  }, []);

  function addAnimation(scrollers: any) {
    scrollers.forEach((scroller: any) => {
      scroller.setAttribute("data-animated", true);
      const scrollerInner = scroller.querySelector(".scroller__inner");
      const scrollerContent = Array.from(scrollerInner.children);
      scrollerContent.forEach((item: any) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true);
        scrollerInner.appendChild(duplicatedItem);
      });
    });
  }

  return (
    <section ref={ref} className={cn(sectionBackground, sectionSpacing)}>
      <div>
        <h5 className="text-h5 text-black text-center font-semibold mb-14 px-4">
          Join the movement of companies doing the right thing{" "}
        </h5>
      </div>
      <div>
        <Scroller>
          <div className="scroller__inner company-list-top">
            {data.companyListTop.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className="aspect-square max-w-[86px] sm:max-w-[172px] flex items-center justify-center bg-white rounded-2xl"
                >
                  <Image
                    src={urlForImage(item).url()}
                    width={500}
                    height={500}
                    className="w-[172px] p-4"
                    alt={item.alt}
                  />
                </li>
              );
            })}
          </div>
        </Scroller>
      </div>
      <div>
        <Scroller>
          <div className="scroller__inner company-list-bottom">
            {data.companyListBottom.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className="aspect-square max-w-[86px] sm:max-w-[172px] flex items-center justify-center bg-white rounded-2xl"
                >
                  <Image
                    src={urlForImage(item).url()}
                    width={500}
                    height={500}
                    className="w-[172px] p-4"
                    alt={item.alt}
                  />
                </li>
              );
            })}
          </div>
        </Scroller>
      </div>
      <div className="block md:hidden">
        <Scroller>
          <div className="scroller__inner company-list-top">
            {data.companyListTop.map((item: any, index: number) => {
              return (
                <li
                  key={index}
                  className="aspect-square max-w-[86px] sm:max-w-[172px] flex items-center justify-center bg-white rounded-2xl"
                >
                  <Image
                    src={urlForImage(item).url()}
                    width={500}
                    height={500}
                    className="w-[172px] p-4"
                    alt={item.alt}
                  />
                </li>
              );
            })}
          </div>
        </Scroller>
      </div>
      <div className="py-8 sm:py-12"></div>
    </section>
  );
};

export default CompanyShowcase;
